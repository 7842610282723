<template>
    <section class="config-fecha-pago ml-4" v-loading="loading">
        <p class="f-20 f-500">Limites de mensajes</p>
        <p class="f-15 my-4">Asigne un número limite de mensajes para enviar por los residentes cada vez que el administrador le envíe un mensaje.</p>
        <ValidationObserver tag="div" class="mt-4">
            <div class="row mb-4">
                <p class="col-12 f-12 pl-4 label-inputs">Número de mensajes</p>
                <div class="col-auto">
                    <ValidationProvider tag="div">
                        <el-input placeholder="Cantidad" v-model="chat.numero" size="small"></el-input>
                    </ValidationProvider>
                </div>
                <div class="col-auto my-auto">
                    <div class="d-middle-center">
                        <p class="f-15">Mensajes ilimitados</p>
                        <ValidationProvider tag="div">
                            <el-switch :value="mensajes" :active-value="1" :inactive-value="0" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" @change="updateLimit" />
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <!-- btn guardar -->
            <div class="row mt-2 mt-sm-4">
                <div class="col-12">
                    <button class="btn btn-general f-14 f-300 px-3" @click="guardar">Guardar</button>
                </div>
            </div>
        </ValidationObserver>
        <!-- Partials -->
        <modal-set-fecha-implementacion ref="abrirSetFechaImplementacion" />
    </section>
</template>
<script>
import XConfiguraciones from '~/services/xconfiguraciones'
export default {
    components:{
        modalSetFechaImplementacion: () => import('./partials/modalSetFechaImplementacion')
    },
    data(){
        return{
            input1: '',
            value2: false,
            chat: {
              id: null,
              tipo: 21,
              numero: null
            },
            loading: false,
        }
    },
    created(){
      this.obtenerInfo()
    },
    computed:{
      mensajes(){
        if(this.chat.numero > 0){
          return 0
        }else{
          return 1
        }
      }
    },
    methods:{
        async obtenerInfo(){
          try {
            //this.loading = true
            const {data} = await XConfiguraciones.obtener(21)
            console.log("🚀 ~ file: limitesMensajes.vue:64 ~ obtenerInfo ~ data:", data)
            if (data.data) {
              this.chat = data.data
            }
            //this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }
        },
        updateLimit(e) {
          if(e == 1){
            this.chat.numero = null
          }else{
            this.chat.numero = 4
          }
        },
        async guardar(){
          try {
            this.loading = true
            let payload = {
              tipo : this.chat.tipo,
              numero : this.chat.numero
            }
            
            const {data} = await XConfiguraciones.guardarConfiguracion(payload)
            if(data.success){
              this.obtenerInfo()
              this.$notify({
                title: 'Límite de mensajes',
                message: data.mensaje,
                type: 'success'
              });
            }
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
              this.$notify({
                title: 'Límite de mensajes',
                message: 'error guardando los mensajes',
                type: 'warning'
              });
          } 
        }
    }
}
</script>
<style lang="scss" scoped>
.config-fecha-pago{
    .btn-general{
        min-width: 136px;
        height: 32px;
    }
}
</style>